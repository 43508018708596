import  React  from "react";
import { Header } from './Header';
import { AlbumGrid } from "./AlbumGrid";
import {Footer} from "./Footer";

export function Albums(props){

    return (
        <React.Fragment>
            <Header showTabs={true} tabIndex={1} title='albums'
                    photoModeDispatch={props.photoModeDispatch} 
                    photoMode={props.photoMode}/>
            <AlbumGrid/>
            <Footer/>
        </React.Fragment>
    );

}
