import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Link} from "react-router-dom";


const useStyles = makeStyles({
    gridCell:{
        // display: 'flex',
        // justifyContent: 'center',
        // alignContent: 'center',
        position: 'relative',
        textAlign: 'center',
        color: 'white'
    },
    bottomLeft: {
        position: 'absolute',
        bottom: 8,
        left: 16
    },
    titleText: {
        textShadowColor: '#000000',
        textShadowOffset: {width: -1, height: 1},
        textShadowRadius: 10,
        lineHeight: 20,
        wordBreak: 'break-word',
        Margin: 0
    }

});

export function AlbumGridCell(props){
    const classes = useStyles();
    return (
        <Link to={props.link}>
            <div className={classes.gridCell}>
                <img className={classes.titleText} alt={props.alt} height="250"  width="250" src={props.image}></img>
                <div className={classes.bottomLeft}><h4>{props.alt}</h4></div>
            </div>
        </Link>

        );
}
