import { useEffect, useCallback } from 'react';
let inSerialCall = false;
let callStack = [];//wait unitl we get a response to make another call, this is so we don't loose sequence.
// make API calls and pass the returned data via dispatch
export const useFetch = (page, dispatch, album, imageChunkSize) => {
  useEffect(() => {
    const callhttp = () => {
      if(!inSerialCall){
        return;
      }
      dispatch({ type: 'FETCHING_IMAGES', fetching: true });
      fetch(`/v1/api/photos?album=${album}&page=${page}&limit=${imageChunkSize}`)
        .then(data => data.json())
        .then(images => {
          dispatch({ type: 'STACK_IMAGES', images });
          dispatch({ type: 'FETCHING_IMAGES', fetching: false });
          if(callStack.length > 0){
            inSerialCall = true;
            callStack.shift()();
            return;
          }
          inSerialCall = false;
        })
        .catch(e => {
          dispatch({ type: 'FETCHING_IMAGES', fetching: false });
          inSerialCall = false;
          if(callStack.length > 0){
            inSerialCall = true;
            callStack.shift()();
            return;
          }
          inSerialCall = false;
          return e;
        })
    };
    callStack.push(callhttp);
    if(!inSerialCall){
      inSerialCall = true;
      callStack.shift()();
    }
    
  }, [dispatch, page, album, imageChunkSize])
};

// infinite scrolling with intersection observer
export const useInfiniteScroll = (scrollRef, dispatch) => {
    const scrollObserver = useCallback(
      node => {
        new IntersectionObserver(entries => {
          entries.forEach(en => {
            if (en.intersectionRatio > 0) {
              dispatch({ type: 'ADVANCE_PAGE' });
            }
          });
        }).observe(node);
      },
      [dispatch]
    );
    useEffect(() => {
      if (scrollRef.current) {
        scrollObserver(scrollRef.current);
      }
    }, [scrollObserver, scrollRef]);
};
