import React  from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import { Root } from "./Root";
import {
  BrowserRouter as Router,
  //,useHistory
} from "react-router-dom";
import HttpsRedirect from 'react-https-redirect';
import ReactGA from 'react-ga';
import { createBrowserHistory } from "history";

const history = createBrowserHistory();
// Initialize google analytics page view tracking
history.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  });

const trackingId = "UA-179173399-1";
ReactGA.initialize(trackingId);

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      //main: '#ff4400',
      main: '#a6d4fa'
    },
    secondary: {
      main: '#fead89'
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});
// ========================================
ReactDOM.render(
  <HttpsRedirect>
      <ThemeProvider theme={theme}>
        <Router>
          <Root/>
        </Router>
      </ThemeProvider>
  </HttpsRedirect>,
    document.getElementById('root')
);