import React  from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Link, useHistory} from "react-router-dom";
import PersonPinIcon from '@material-ui/icons/PersonPin';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import HomeIcon from '@material-ui/icons/Home';
import PhotoIcon from '@material-ui/icons/Photo';






export function Header(props)  {
    let headerBackgroundClass = 'header-img';
    if(props.title === 'portraits'){
        headerBackgroundClass = 'header-img-portraits'
    }else if(props.title === 'arquitecture'){
        headerBackgroundClass = 'header-img-arquitecture'
    }else if(props.title === 'street photography'){
        headerBackgroundClass = 'header-img-street'
    }else if(props.title === 'landscapes'){
        headerBackgroundClass = 'header-img-landscapes'
    }else if(props.title === 'about'){
        headerBackgroundClass = 'header-img-about'
    }else if(props.title === 'albums'){
        headerBackgroundClass = 'header-img-albums'
    }
    const useStyles = makeStyles((theme) => ({
        button: {
            margin: theme.spacing(0.2),
        },
        buttonContainer:{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },

        // icon: {
        //     marginRight: theme.spacing(2),
        // },
        linkSelected: {
            display: 'flex',
            color: '#fead89'
        },
        link: {
            display: 'flex',
            color: 'rgba(255, 255, 255, 0.7)'
        },
        icon: {
            marginRight: theme.spacing(0.5),
            width: 20,
            height: 20,
        },
        heroContent: {
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(8, 0, 6),
        },
        heroButtons: {
            marginTop: theme.spacing(4),
        },
        footer: {
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(6),
        },
        root: {
            flexGrow: 1
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(2)
        },
        titleName: {
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(0),
            [`${theme.breakpoints.down('md')}`]: {
                marginTop: theme.spacing(4)
              }
        },
        large: {
        width: theme.spacing(20),
        height: theme.spacing(20),
        marginTop: theme.spacing(9),
        [`${theme.breakpoints.down('md')}`]: {
            width: theme.spacing(12),
            height: theme.spacing(12),
            marginTop: theme.spacing(6)
          },
          [`${theme.breakpoints.down('xs')}`]: {
            width: theme.spacing(12),
            height: theme.spacing(12),
            marginTop: theme.spacing(2)
          },
            // [`${theme.breakpoints.down('sm')} and (orientation: portrait)`]: {
            //   width: theme.spacing(6),
            //   height: theme.spacing(6)
            // },
            // [`${theme.breakpoints.up('md')} and (orientation: portrait)`]: {
            //   width: theme.spacing(7),
            //   height: theme.spacing(7)
            // },
            // [`${theme.breakpoints.up('lg')}`]: {
            //   width: theme.spacing(8),
            //   height: theme.spacing(8)
            // }
        },
        appBar:{
            minHeight: 90,
            display: 'flex',
            justifyContent: 'center',
            [`${theme.breakpoints.down('xs')} and (orientation: portrait)`]: {
              minHeight: 64
            },
            [`${theme.breakpoints.down('sm')} and (orientation: portrait)`]: {
              minHeight: 70
            },
            [`${theme.breakpoints.up('md')} and (orientation: portrait)`]: {
              minHeight: 80
            },
            [`${theme.breakpoints.up('md')} and (orientation: portrait)`]: {
              minHeight: 90
            }
            // [`${theme.breakpoints.down('md')} and (orientation: landscape)`]: {
            //   minHeight: 48
            // }
        }
    }));
    const classes = useStyles();
    const [value, setValue] = React.useState(props.tabIndex);
    const history = useHistory();
    const handleChange = (event, newValue) => {
      setValue(newValue);
      setTimeout(() => {
        switch(newValue){
            case 0:
                //home
                history.push('/');
                if(props.photoModeDispatch && props.photoMode){
                  setTimeout(()=>{                
                    props.photoModeDispatch({type: 'LIST', startAtIndex: props.photoMode.startAtIndex});
                    //give some time to create the refs. to be able to scroll to selected item.
                  },100);
                }
                break;
            case 1:
                history.push('/albums');
                //about
                break;
            case 2:
                history.push('/about');
                //about
                break;
            default:
                //home
          }
      }, 300);
    };
    function a11yProps(index) {
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
      };
    }
    return (
          <React.Fragment>
            <CssBaseline />
            <div className={headerBackgroundClass}>
              <div className={classes.root}>
                <div>
                  <div className={'logo-text-header-layout'}>
                        {/* <img className={'logo-place'} height='50' src='./logo.png'></img> */}
                        <div></div>
                        {(props.showTabs  && value >= 0) ?
                              (<Tabs textColor="secondary" className={'tab-buttons ' + ' not-visible-mobile blur-background'} value={value} onChange={handleChange} aria-label="tabs menu" centered>
                                    <Tab icon={<HomeIcon/>}  label="Photostream" {...a11yProps(0)}>
                                    </Tab>
                                    <Tab icon={<PhotoAlbumIcon/>} label="Albums" {...a11yProps(1)}>
                                    </Tab>
                                    <Tab icon={<PersonPinIcon />}  label="About" {...a11yProps(2)}>
                                    </Tab>
                                </Tabs>) : null}
                        {(props.showTabs  && value >= 0) ? <div></div> : null}
                                
                        {(value < 0) ? (<div className={classes.buttonContainer + ' breadcrums-no-mobile' + ' not-visible-mobile' + ' blur-background'}>
                                                    {/* <Button color="primary"
                                                            variant="outlined"
                                                            size="small"
                                                            className={classes.button}
                                                            onClick={()=> {history.push('/albums')}}
                                                            startIcon={<ArrowBackIcon/>}
                                                            fullWidth>GO BACK TO ALBUMS...
                                                    </Button> */}
                                                    
                                                    <Breadcrumbs aria-label="breadcrumb">
                                                              <Link to={'/'} className={classes.link}>
                                                                      <HomeIcon className={classes.icon} />
                                                                          photostream
                                                              </Link>
                                                              <Link
                                                                      
                                                                      onClick={()=> {history.push('/albums')}}
                                                                      className={classes.link }
                                                                  >
                                                                      <PhotoAlbumIcon className={classes.icon} />
                                                                      albums
                                                              </Link>
                                                              <Typography color="secondary" className={classes.linkSelected }>
                                                                  <PhotoCameraIcon className={classes.icon} />
                                                                  {props.title}
                                                              </Typography>
                                                          </Breadcrumbs>
                                                </div>) : null}
                        {(value < 0) ? <div></div> : null }
                  </div>
                  <div className={classes.appBar }>
                  {props.title === 'photostream' ? 
                      <div className={ 'header-layout'}>
                        <Link to={'/'}>
                          <Avatar className={classes.large} alt="Nicolas Larrea" src="/photos/nicolaslarrea/avatar/nicolaslarrea.jpg" />
                        </Link>
                        <Typography  variant="h5" className={classes.titleName} color="inherit">Nicolás Larrea</Typography> 
                        <Typography  variant="h4" className={classes.title + ' header-tit'} color="inherit">
                          {props.title}
                        </Typography>
                      </div>
                      : 
                      <div className={'album-header-layout'}>
                        <Typography  variant="h4" className={classes.title + ' header-tit header-album'} color="inherit">
                          {props.title}
                        </Typography>
                      </div>
                      
                      } 

                  </div>
                </div> 
              </div>
            </div>
          {(props.showTabs  && value >= 0) ?
          (<Tabs textColor="secondary"   classes={{root: 'tab-buttons-mobile mobile-visible'}} value={value} onChange={handleChange} aria-label="tabs menu" centered>
                <Tab  icon={<HomeIcon/>}  label="Photostream" {...a11yProps(0)}>
                </Tab>
                <Tab icon={<PhotoAlbumIcon/>} label="Albums" {...a11yProps(1)}>
                </Tab>
                <Tab icon={<PersonPinIcon />}  label="About" {...a11yProps(2)}>
                </Tab>
            </Tabs>) : null}
              {(value < 0) ?
                  (<div className={classes.buttonContainer + ' mobile-visible'}>
                      {/* <Button color="primary"
                              variant="outlined"
                              size="small"
                              className={classes.button}
                              onClick={()=> {history.push('/albums')}}
                              startIcon={<ArrowBackIcon/>}
                              fullWidth>GO BACK TO ALBUMS...
                      </Button> */}
                      <Breadcrumbs aria-label="breadcrumb">
                                <Link to={'/'} className={classes.link}>
                                        <HomeIcon className={classes.icon} />
                                            photostream
                                </Link>
                                <Link
                                        
                                        onClick={()=> {history.push('/albums')}}
                                        className={classes.link }
                                    >
                                        <PhotoAlbumIcon className={classes.icon} />
                                        albums
                                </Link>
                                <Typography color="secondary" className={classes.linkSelected }>
                                    <PhotoCameraIcon className={classes.icon} />
                                    {props.title}
                                </Typography>
                            </Breadcrumbs>
                  </div>) : null}

        </React.Fragment>
    );
}
