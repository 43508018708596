import React, { useReducer, useEffect } from 'react';
import { Carousel } from './Carousel';
import { Photostream } from './Photostream';
import { About } from './About';
import {Route,useHistory} from "react-router-dom";
import { useFetch } from './customHooks';
import { Albums } from './Albums';
import ReactGA from 'react-ga';


export function Root(){
    const imageChunkSize = 30;
    //   function clearSelection(){
    //     if (window.getSelection) {window.getSelection().removeAllRanges();}
    //     else if (document.selection) {document.selection.empty();}
    //   }
    const history = useHistory();
    function onCarouselGoBackClick(route, photoModeDispatch, photoMode){
        history.push(route);
        setTimeout(()=>{
            photoModeDispatch({type: 'LIST', startAtIndex: photoMode.startAtIndex});
            //give some time to create the refs. to be able to scroll to selected item.
        },100);
        ReactGA.event({
            category: "onCarouselGoBack",
            action: `Go back to route: ${route}`
          });
        //clearSelection();
    }
    const onPhotostreamImageClick = (startAtIndex, route, photoModeDispatch) => {
        if(window.isMobile()){
            return;//don't use carousel for mobile devices.
        }
        photoModeDispatch({type: 'CAROUSEL', startAtIndex: startAtIndex});
        const routeIndex = route + '/' + (startAtIndex + 1);
        history.push(routeIndex);
        ReactGA.event({
            category: "onPhotostreamImage",
            action: `Go back to route: ${route} with index${startAtIndex}`
          });
    }
    const imgReducer = (state, action) => {
        switch (action.type) {
          case 'STACK_IMAGES':
            const images = state.images.concat(action.images);
            
            const imgMap = images.reduce((map, img) => {
              map.set(img.src, img);
              return map;
            }, new Map());
            const uniqueImages = Array.from(imgMap.values());
            const refs = uniqueImages.reduce((acc, img) => {
              acc[img.src] = React.createRef();
              return acc;
            }, {...state.refs});
            const imagesGallery = uniqueImages.map(img => {return {original: img.src, thumbnail: img.thumbnail}});
            const size = action.images.length > 0 ? action.images[0].size : 0;
            return { ...state, images: uniqueImages, refs: refs, imagesGallery: imagesGallery, size: size }
          case 'FETCHING_IMAGES':
            return { ...state, fetching: action.fetching }
          default:
            return state;
        }
    }
    const photoModeReducer = (state, action) => {
        switch(action.type){
          case 'CAROUSEL':
            return { ...state, startAtIndex: action.startAtIndex, showCarousel: true}
          case 'LIST':
            return { ...state, startAtIndex: action.startAtIndex, showCarousel: false}
          default:
            return state;
        }
    }
    const advancePage = (pagerDispatch) => {
        pagerDispatch({type: 'ADVANCE_PAGE'});
    }
    const advanceIndex = (photoModeDispatch, startAtIndex) => {
        photoModeDispatch({type: 'CAROUSEL', startAtIndex: startAtIndex});
    }
    const resetPage = (pagerDispatch) => {
        pagerDispatch({type: 'RESET_PAGE'});
    }
    const onSlide = (index, imgData, pagerDispatch, photoModeDispatch, route) => {
        if(index + 5 >= imgData.images.length){
            //we are at the end fetch more images if not  in full screen, when in full screen
            //refreshing images cause the control to exit full screen mode.
            pagerDispatch({type: 'ADVANCE_PAGE'});
            //photoModeDispatch({type: 'LIST', startAtIndex: index});//trigger an image reload on te image gallery
        }
        photoModeDispatch({type: 'CAROUSEL', startAtIndex: index});
        const routeIndex = route + '/' + (index + 1);
        history.push(routeIndex);
        ReactGA.event({
            category: "onSlide",
            action: `On slide with route: ${route} and index${index}`
          });
    }
    const onScreenChange = (isFullscreen) => {
    }
    const pageReducer = (state, action) => {
        switch (action.type) {
          case 'ADVANCE_PAGE':
            return { ...state, page: state.page + 1 }
        case 'RESET_PAGE':
                return { ...state, page: 0 }
          default:
            return state;
        }
    };
    function scrollToPhoto(imgData, photoMode, photoModeDispatch) {
        if (imgData && imgData.images && photoMode && imgData.images.length > photoMode.startAtIndex && photoMode.startAtIndex > 0) {
            const photoId = imgData.images[photoMode.startAtIndex].src;
            if (imgData.refs[photoId].current) {
                imgData.refs[photoId].current.scrollIntoView({
                    behavior: 'auto',
                    block: 'center',
                    inline: 'center'
                });
                photoModeDispatch({type: 'LIST', startAtIndex: 0});
                ReactGA.event({
                    category: "scrollToPhoto",
                    action: `On scrollToPhoto with photo: ${photoId}`
                  });
            }
        }
    }
    //___portfolio => PF
    const [imgDataPF, imgDispatchPF] = useReducer(imgReducer,{ images:[], fetching: true, startAtIndex: 0, refs: {}, imagesGallery:[], album: 'photostream', size: -1});
    const [pagerPF, pagerDispatchPF ] = useReducer(pageReducer, { page: 0 });
    const [photoModePF, photoModeDispatchPF] = useReducer(photoModeReducer, {showCarousel: false, startAtIndex: 0});
    useFetch(pagerPF.page, imgDispatchPF, imgDataPF.album, imageChunkSize);
    useEffect(() => scrollToPhoto(imgDataPF, photoModePF, photoModeDispatchPF),[imgDataPF, photoModePF]);
    //___portfolio
    //___portraits => PT
    const [imgDataPT, imgDispatchPT] = useReducer(imgReducer,{ images:[], fetching: true, startAtIndex: 0, refs: {}, imagesGallery:[], album: 'portraits', size: -1});
    const [pagerPT, pagerDispatchPT ] = useReducer(pageReducer, { page: 0 });
    const [photoModePT, photoModeDispatchPT] = useReducer(photoModeReducer, {showCarousel: false, startAtIndex: 0});
    useFetch(pagerPT.page, imgDispatchPT , imgDataPT.album, imageChunkSize);
    useEffect(() => scrollToPhoto(imgDataPT, photoModePT, photoModeDispatchPT),[imgDataPT, photoModePT]);
    //___portraits
    //landscapes => LC
    const [imgDataLC, imgDispatchLC] = useReducer(imgReducer,{ images:[], fetching: true, startAtIndex: 0, refs: {}, imagesGallery:[], album: 'landscapes', size: -1});
    const [pagerLC, pagerDispatchLC ] = useReducer(pageReducer, { page: 0 });
    const [photoModeLC, photoModeDispatchLC] = useReducer(photoModeReducer, {showCarousel: false, startAtIndex: 0});
    useFetch(pagerLC.page, imgDispatchLC, imgDataLC.album, imageChunkSize);
    useEffect(() => scrollToPhoto(imgDataLC, photoModeLC, photoModeDispatchLC),[imgDataLC, photoModeLC]);
    //landscapes
    //___arquitecture => AQ
    const [imgDataAQ, imgDispatchAQ] = useReducer(imgReducer,{ images:[], fetching: true, startAtIndex: 0, refs: {}, imagesGallery:[], album: 'arquitecture', size: -1});
    const [pagerAQ, pagerDispatchAQ ] = useReducer(pageReducer, { page: 0 });
    const [photoModeAQ, photoModeDispatchAQ] = useReducer(photoModeReducer, {showCarousel: false, startAtIndex: 0});
    useFetch(pagerAQ.page, imgDispatchAQ, imgDataAQ.album, imageChunkSize);
    useEffect(() => scrollToPhoto(imgDataAQ, photoModeAQ, photoModeDispatchAQ),[imgDataAQ, photoModeAQ]);
    //___arquitecture
    //streetphotography => SP
    const [imgDataSP, imgDispatchSP] = useReducer(imgReducer,{ images:[], fetching: true, startAtIndex: 0, refs: {}, imagesGallery:[], album: 'streetphotography', size: -1});
    const [pagerSP, pagerDispatchSP ] = useReducer(pageReducer, { page: 0 });
    const [photoModeSP, photoModeDispatchSP] = useReducer(photoModeReducer, {showCarousel: false, startAtIndex: 0});
    useFetch(pagerSP.page, imgDispatchSP, imgDataSP.album, imageChunkSize);
    useEffect(() => scrollToPhoto(imgDataSP, photoModeSP, photoModeDispatchSP),[imgDataSP, photoModeSP]);
    //___streetphotography
    return (
            <React.Fragment>
                <Route exact path="/">
                    {/*photostream*/}
                    <Photostream  images={imgDataPF.images}
                            fetching={imgDataPF.fetching}
                            imgDispatch={imgDispatchPF}
                            photoModeDispatch={photoModeDispatchPF}
                            pagerDispatch={pagerDispatchPF}
                            pager={pagerPF}
                            refs={imgDataPF.refs}
                            photoMode={photoModePF}
                            onPhotostreamImageClick={onPhotostreamImageClick}
                            carouselRoute={'/gallery'}
                            tabIndex={0}
                            title={'photostream'}
                            />
                </Route>
                <Route exact path="/gallery/:index">
                    
                    {/*photostream*/}
                    <Carousel onClick={()=>onCarouselGoBackClick('/',photoModeDispatchPF, photoModePF)}
                        imagesGallery={imgDataPF.imagesGallery}
                        fetching={imgDataPF.fetching}
                        photoModeDispatch={photoModeDispatchPF}
                        pagerDispatch={pagerDispatchPF}
                        startAtIndex={ photoModePF.startAtIndex}
                        onSlide={(index)=> onSlide(index, imgDataPF, pagerDispatchPF, photoModeDispatchPF, '/gallery')}
                        onScreenChange={onScreenChange}
                        photoMode={photoModePF}
                        headerText={'photostream'}
                        title={'gallery'}
                        advancePage={advancePage}
                        advanceIndex={advanceIndex}
                        resetPage={resetPage}
                        carouselRoute={'/gallery'}
                        pager={pagerPF}
                        imgData={imgDataPF}
                        imageChunkSize={imageChunkSize}
                        />
                </Route>
                <Route exact path="/portraits">
                    {/*portraits*/}
                    <Photostream  images={imgDataPT.images}
                                  fetching={imgDataPT.fetching}
                                  imgDispatch={imgDispatchPT}
                                  photoModeDispatch={photoModeDispatchPT}
                                  pagerDispatch={pagerDispatchPT}
                                  pager={pagerPT}
                                  refs={imgDataPT.refs}
                                  photoMode={photoModePT}
                                  onPhotostreamImageClick={onPhotostreamImageClick}
                                  carouselRoute={'/gallery/portraits'}
                                  showOnlyAlbums={true}
                                  tabIndex={-1}
                                  title={'portraits'}
                    />
                </Route>
                <Route exact path="/gallery/portraits/:index">
                    {/*portraits*/}
                    <Carousel onClick={()=>onCarouselGoBackClick('/portraits',photoModeDispatchPT, photoModePT)}
                              imagesGallery={imgDataPT.imagesGallery}
                              fetching={imgDataPT.fetching}
                              photoModeDispatch={photoModeDispatchPT}
                              pagerDispatch={pagerDispatchPT}
                              startAtIndex={photoModePT.startAtIndex}
                              onSlide={(index)=> onSlide(index, imgDataPT, pagerDispatchPT, photoModeDispatchPT, '/gallery/portraits')}
                              onScreenChange={onScreenChange}
                              photoMode={photoModePT}
                              headerText={'portraits'}
                              title={'gallery portraits'}
                              advancePage={advancePage}
                              advanceIndex={advanceIndex}
                              resetPage={resetPage}
                              carouselRoute={'/gallery/portraits'}
                              pager={pagerPT}
                              imgData={imgDataPT}
                              imageChunkSize={imageChunkSize}
                    />
                </Route>
                <Route exact path="/landscapes">
                    {/*landscapes*/}
                    <Photostream  images={imgDataLC.images}
                                  fetching={imgDataLC.fetching}
                                  imgDispatch={imgDispatchLC}
                                  photoModeDispatch={photoModeDispatchLC}
                                  pagerDispatch={pagerDispatchLC}
                                  pager={pagerLC}
                                  refs={imgDataLC.refs}
                                  photoMode={photoModeLC}
                                  onPhotostreamImageClick={onPhotostreamImageClick}
                                  carouselRoute={'/gallery/landscapes'}
                                  showOnlyAlbums={true}
                                  tabIndex={-1}
                                  title={'landscapes'}
                    />
                </Route>
                <Route exact path="/gallery/landscapes/:index">
                    {/*landscapes*/}
                    <Carousel onClick={()=>onCarouselGoBackClick('/landscapes',photoModeDispatchLC, photoModeLC)}
                              imagesGallery={imgDataLC.imagesGallery}
                              fetching={imgDataLC.fetching}
                              photoModeDispatch={photoModeDispatchLC}
                              pagerDispatch={pagerDispatchLC}
                              startAtIndex={photoModeLC.startAtIndex}
                              onSlide={(index)=> onSlide(index, imgDataLC, pagerDispatchLC, photoModeDispatchLC, '/gallery/landscapes')}
                              onScreenChange={onScreenChange}
                              photoMode={photoModeLC}
                              headerText={'landscapes'}
                              title={'gallery landscapes'}
                              advancePage={advancePage}
                              advanceIndex={advanceIndex}
                              resetPage={resetPage}
                              carouselRoute={'/gallery/landscapes'}
                              pager={pagerLC}
                              imgData={imgDataLC}
                              imageChunkSize={imageChunkSize}
                    />
                </Route>
                <Route exact path="/arquitecture">
                    {/*arquitecture*/}
                    <Photostream  images={imgDataAQ.images}
                                  fetching={imgDataAQ.fetching}
                                  imgDispatch={imgDispatchAQ}
                                  photoModeDispatch={photoModeDispatchAQ}
                                  pagerDispatch={pagerDispatchAQ}
                                  pager={pagerAQ}
                                  refs={imgDataAQ.refs}
                                  photoMode={photoModeAQ}
                                  onPhotostreamImageClick={onPhotostreamImageClick}
                                  carouselRoute={'/gallery/arquitecture'}
                                  showOnlyAlbums={true}
                                  tabIndex={-1}
                                  title={'arquitecture'}
                    />
                </Route>
                <Route exact path="/gallery/arquitecture/:index">
                    {/*arquitecture*/}
                    <Carousel onClick={()=>onCarouselGoBackClick('/arquitecture',photoModeDispatchAQ, photoModeAQ)}
                              imagesGallery={imgDataAQ.imagesGallery}
                              fetching={imgDataAQ.fetching}
                              photoModeDispatch={photoModeDispatchAQ}
                              pagerDispatch={pagerDispatchAQ}
                              startAtIndex={photoModeAQ.startAtIndex}
                              onSlide={(index)=> onSlide(index, imgDataAQ, pagerDispatchAQ, photoModeDispatchAQ, '/gallery/arquitecture')}
                              onScreenChange={onScreenChange}
                              photoMode={photoModeAQ}
                              headerText={'arquitecture'}
                              title={'gallery landscapes'}
                              advancePage={advancePage}
                              advanceIndex={advanceIndex}
                              resetPage={resetPage}
                              carouselRoute={'/gallery/arquitecture'}
                              pager={pagerAQ}
                              imgData={imgDataAQ}
                              imageChunkSize={imageChunkSize}
                    />
                </Route>
                <Route exact path="/streetphotography">
                    {/*streetphotography*/}
                    <Photostream  images={imgDataSP.images}
                                  fetching={imgDataSP.fetching}
                                  imgDispatch={imgDispatchSP}
                                  photoModeDispatch={photoModeDispatchSP}
                                  pagerDispatch={pagerDispatchSP}
                                  pager={pagerSP}
                                  refs={imgDataSP.refs}
                                  photoMode={photoModeSP}
                                  onPhotostreamImageClick={onPhotostreamImageClick}
                                  carouselRoute={'/gallery/streetphotography'}
                                  tabIndex={-1}
                                  title={'street photography'}
                    />
                </Route>
                <Route exact path="/gallery/streetphotography/:index">
                    {/*streetphotography*/}
                    <Carousel onClick={()=>onCarouselGoBackClick('/streetphotography',photoModeDispatchSP, photoModeSP)}
                              imagesGallery={imgDataSP.imagesGallery}
                              fetching={imgDataSP.fetching}
                              photoModeDispatch={photoModeDispatchSP}
                              pagerDispatch={pagerDispatchSP}
                              startAtIndex={photoModeSP.startAtIndex}
                              onSlide={(index)=> onSlide(index, imgDataSP, pagerDispatchSP, photoModeDispatchSP, '/gallery/streetphotography')}
                              onScreenChange={onScreenChange}
                              photoMode={photoModeSP}
                              headerText={'street photography'}
                              title={'gallery streetphotography'}
                              advancePage={advancePage}
                              advanceIndex={advanceIndex}
                              resetPage={resetPage}
                              carouselRoute={'/gallery/streetphotography'}
                              pager={pagerSP}
                              imgData={imgDataSP}
                              imageChunkSize={imageChunkSize}
                    />
                </Route>
                <Route exact path="/albums">
                    <Albums photoModeDispatch={photoModeDispatchPF}
                            photoMode={photoModePF}/>
                </Route>
                <Route exact path="/about">
                    <About photoModeDispatch={photoModeDispatchPF}
                           photoMode={photoModePF}/>
                </Route>
            </React.Fragment>
    );
} 
