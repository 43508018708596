import React from 'react';
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import './index.css';
import { CarouselHeader } from './CarouselHeader';
import {useLocation, useHistory} from 'react-router-dom';

export function Carousel(props){
    const location = useLocation();
    const history = useHistory();
    if(props.fetching){
        return null;//TODO: display loading images
    }
    let match = location.pathname.match(/(\d+)/);
    if(!match){ match=[1]; }
    let overrideIndex = props.startAtIndex;
    if(match.length > 0 && (parseInt(match[0])-1 >= 0)){
        overrideIndex = (parseInt(match[0])-1);
    }
    const maxImages = (props.pager.page + 1)* props.imageChunkSize;
    if(props.imgData.size > 0 && overrideIndex >= props.imgData.size){
        props.resetPage(props.pagerDispatch);
        props.advanceIndex(props.photoModeDispatch, 0);
        history.push(props.carouselRoute + '/1');
        return null;
    }else if(props.imagesGallery.length > 0 && overrideIndex >= maxImages){
        props.advancePage(props.pagerDispatch);
        return null;
    }
    
    if(overrideIndex !== props.startAtIndex){
        props.advanceIndex(props.photoModeDispatch, overrideIndex);
    }

    return (<div>
                    <CarouselHeader onClick={props.onClick} 
                                    headerText={props.headerText}
                    />
                    <ImageGallery   items={props.imagesGallery} 
                                    startIndex={props.startAtIndex}
                                    showIndex={true} 
                                    disableThumbnailScroll={false} 
                                    onSlide={props.onSlide}
                                    onScreenChange={props.onScreenChange}
                    />
            </div>)
}
