import React from 'react';
import { AlbumGridCell } from "./AlbumGridCell";
import Paper from "@material-ui/core/Paper";


export function AlbumGrid(){
    return (
                <div className={'album-grid-body-container-parent'}>
                    <div className={'album-grid-body-container'}>
                        <div className={'album-grid-cell-a'}></div>
                        <Paper className={'album-grid-cell-b album-grid-body'}>
                            <AlbumGridCell link={"/portraits"} alt={'Portraits'} image={"/photos/nicolaslarrea/photostream/43354609255_173f09e94f_q.jpg"} className={'album-grid-cell-a'}/>
                            <AlbumGridCell link={"/landscapes"} alt={'Landscapes'} image={"/photos/nicolaslarrea/photostream/11637352164_18c4785cc4_q.jpg"} className={'album-grid-cell-b'}/>
                            <AlbumGridCell link={"/arquitecture"} alt={'Aquitecture'} image={"/photos/nicolaslarrea/photostream/28602572987_2dc0182c17_q.jpg"} className={'album-grid-cell-c'}/>
                            <AlbumGridCell link={"/streetphotography"} alt={'Street Photography'} image={"/photos/nicolaslarrea/photostream/39912221695_273e43147c_q.jpg"} className={'album-grid-cell-e'}/>
                            {/*<AlbumGridCell link={"/albums"} alt={'Travel'} image={"/photos/nicolaslarrea/photostream/7096721281_c57b46ecb3_q.jpg"} className={'album-grid-cell-f'}/>*/}
                            {/*<AlbumGridCell link={"/albums"} alt={'Night'} image={"/photos/nicolaslarrea/photostream/34132017892_6cdc945601_q.jpg"} className={'album-grid-cell-g'}/>*/}
                        </Paper>
                        <div className={'album-grid-cell-c'}></div>
                    </div>
                </div>

            );
}
