import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
// import Link from '@material-ui/core/Link';
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import TheatersIcon from '@material-ui/icons/Theaters';
import HomeIcon from '@material-ui/icons/Home';
import {Link, useHistory} from "react-router-dom";


export function CarouselHeader(props){
    const history = useHistory();
    const useStyles = makeStyles((theme) => ({
        button: {
            margin: theme.spacing(0.2)
        },
        galleryButtonContainer:{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '5px',
            paddingBottom: '5px'
        },
        linkSelected: {
            display: 'flex',
            color: '#fead89'
        },
        link: {
            display: 'flex',
            color: 'rgba(255, 255, 255, 0.7)'
        },
        icon: {
            marginRight: theme.spacing(0.5),
            width: 20,
            height: 20,
        }
    }));
    const classes = useStyles();
    return (<div className={classes.galleryButtonContainer}>
                            {/* <Button color="primary"
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={props.onClick} 
                                startIcon={<ArrowBackIcon/>}
                                fullWidth>{props.headerText}
                            </Button> */}
                            
                                

                                {props.headerText === 'photostream' ? 
                                    (<Breadcrumbs aria-label="breadcrumb">
                                        <Link href="javascript:void(0)"
                                                    color="secondary"
                                                    onClick={props.onClick}
                                                    className={classes.link}>
                                                    <HomeIcon className={classes.icon} />
                                                    photostream
                                                </Link>
                                        <Typography color="secondary" className={classes.linkSelected}>
                                            <TheatersIcon className={classes.icon} />
                                            gallery
                                        </Typography>
                                    </Breadcrumbs>
                                    ) : 
                                    (<Breadcrumbs aria-label="breadcrumb">
                                                <Link color="secondary" href="javascript:void(0)" onClick={()=> {history.push('/albums')}} className={classes.link}>
                                                    <PhotoAlbumIcon className={classes.icon} />
                                                    albums
                                                </Link>
                                                <Link href="javascript:void(0)"
                                                    color="secondary"
                                                    onClick={props.onClick}
                                                    className={classes.link}>
                                                    <PhotoCameraIcon className={classes.icon} />
                                                    {props.headerText}
                                                </Link>
                                                <Typography color="secondary" className={classes.linkSelected}>
                                                    <TheatersIcon className={classes.icon} />
                                                    gallery
                                                </Typography>
                                    </Breadcrumbs>) }
                                    

                            

            </div>);
}
