import React, {useRef}  from 'react';
import { Footer } from './Footer';
import { Header } from './Header';
import PropTypes from 'prop-types';
import JustifiedLayout from '@codekraft-studio/react-justified-layout';
import {  useInfiniteScroll } from './customHooks';
import Paper from "@material-ui/core/Paper";
import './index.css';

function SHThumbnail(props){
    function renderImage(props) {
      return (
        <div id='images' className="">
            <img id={props.photo} className="card-img-top"  src={props.photo} alt={props.alt} style={props.style}
                onClick={props.onPhotostreamImageClick} ref={props.refs[props.photo]}>
            </img>
        </div>
      );
    }
    return  (
      renderImage(props)
    );
}
function SHGrid(props)  {
    let bottomBoundaryRef = useRef(null);
    useInfiniteScroll(bottomBoundaryRef, props.pagerDispatch);
        const boxesOptions = {
          containerPadding: 5,
          boxSpacing: 5,
          targetRowHeight: 380
        };
        return (
          <Paper className={'album-grid-body-container-no-display'}>
            <JustifiedLayout items={props.photos} options={boxesOptions} >
              {
                (items) => items.map(
                  (photo, index) => {
                    return(
                      <SHThumbnail key={photo.id} 
                      photo={photo.src} alt={photo.alt} refs={props.refs} 
                      style={photo.style} photoModeDispatch={props.photoModeDispatch}
  
                      onPhotostreamImageClick={()=>props.onPhotostreamImageClick(index, props.carouselRoute, props.photoModeDispatch)}
                      ></SHThumbnail>
                    );
                  }
                )
              }
            </JustifiedLayout>
            {props.fetching && (<div><p>Getting images ...</p></div>)}
            <div id='page-bottom-boundary' ref={bottomBoundaryRef}></div>
          </Paper>
        );
}
SHGrid.propTypes = {
    photos: PropTypes.array
};
export function Photostream(props){
    return (
        <React.Fragment> 
                    <Header showTabs={true} tabIndex={props.tabIndex} title={props.title}
                            carouselRoute={props.carouselRoute}
                            photoModeDispatch={props.photoModeDispatch}
                            photoMode={props.photoMode}
                            />
                    <SHGrid photos={props.images} 
                            fetching={props.fetching} 
                            imgDispatch={props.imgDispatch} 
                            photoModeDispatch={props.photoModeDispatch} 
                            pager={props.pager} 
                            pagerDispatch={props.pagerDispatch}
                            onPhotostreamImageClick={props.onPhotostreamImageClick}
                            carouselRoute={props.carouselRoute}
                            refs={props.refs}/>
                    <Footer/> 
        </React.Fragment>
    );

}
